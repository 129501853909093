<template>
  <b-card no-body>
    <b-card-body>
      <service-r-p-a-search-bar :filter-action="filterData" />
    </b-card-body>
    <template v-if="dataLoading">
      <loading />
    </template>
    <template v-else>
      <b-table
        responsive
        :items="dataList"
        striped
        small
        class="font-small-3 text-nowrap"
      />
      <b-card-footer>
        <b-row>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCounts"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col>
            <div class="text-right">
              <p>Toplam <b>{{ dataCounts }}</b> adet sonuç bulundu.</p>
            </div>
          </b-col>
        </b-row>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BCardFooter, BRow, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ServiceRPASearchBar from '@/views/Reports/Service/RPA/SearchBar.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Index',
  components: {
    Loading,
    ServiceRPASearchBar,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BRow,
    BCol,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,

      dataQuery: {
        select: [
          'tbl_IsEmirleri.Belge_Tipi            AS Belge_Tipi',
          'tbl_IsEmirleri.Randevu_No             AS Randevu_No',
          'tbl_IsEmirleri.Musteri_Adi            AS Musteri_Adi',
          'tbl_IsEmirleri.TC_Kimlik              AS TC_Kimlik',
          'tbl_IsEmirleri.Telefon                AS Telefon',
          'tbl_IsEmirleri.E_Posta                AS E_Posta',
          'tbl_IsEmirleri.Marka                  AS Marka',
          'tbl_IsEmirleri.Arac_Model             AS Arac_Model',
          'tbl_IsEmirleri.Model_Yili             AS Model_Yili',
          'tbl_IsEmirleri.Plaka                  AS Plaka',
          'tbl_IsEmirleri.Islem_Tipi             AS Islem_Tipi',
          'tbl_IsEmirleri.tarih_saat             AS tarih_saat',
          'DATE_FORMAT(tbl_IsEmirleri.Randevu_Tarihi,"%d.%m.%Y")         AS Randevu_Tarihi',
          'tbl_IsEmirleri.Lokasyon               AS Lokasyon',
          'tbl_IsEmirleri.Durum                  AS Durum',
          'tbl_IsEmirleri.Danisman               AS Danisman',
          'tbl_IsEmirleri.Temas_Tipi             AS Temas_Tipi',
          'tbl_IsEmirleri.KVKK                   AS KVKK',
          'tbl_IsEmirleri.Il_Ilce                AS Il_Ilce',
          'tbl_IsEmirleri.Is_Emri_No             AS Is_Emri_No',
          'tbl_IsEmirleri.Sase_No                AS Sase_No',
          'tbl_IsEmirleri.Motor_No               AS Motor_No',
          'tbl_IsEmirleri.Km                     AS Km',
          'DATE_FORMAT(tbl_IsEmirleri.DB_Yazdirma_Tarihi,"%d.%m.%Y %H:%m:%s")         AS DB_Yazdirma_Tarihi',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceRPA/dataList']
    },
    dataCounts() {
      return this.$store.getters['serviceRPA/dataCounts']
    },
    filterParams() {
      return this.$store.getters['serviceRPA/dataFilter']
    },
    saveData() {
      return this.$store.getters['serviceRPA/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['serviceRPA/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    filterData() {
      const where = {}
      if (this.filterParams.keywords) {
        this.dataQuery.or_like = {
          'tbl_IsEmirleri.Musteri_Adi': this.filterParams.keywords,
          'tbl_IsEmirleri.TC_Kimlik': this.filterParams.keywords,
          'tbl_IsEmirleri.Telefon': this.filterParams.keywords,
          'tbl_IsEmirleri.E_Posta': this.filterParams.keywords,
          'tbl_IsEmirleri.Arac_Model': this.filterParams.keywords,
          'tbl_IsEmirleri.Marka': this.filterParams.keywords,
          'tbl_IsEmirleri.Plaka': this.filterParams.keywords,
          'tbl_IsEmirleri.Danisman': this.filterParams.keywords,
        }
      } else {
        delete this.dataQuery.or_like
      }
      if (this.filterParams.randevu_starihi) {
        where['date(tbl_IsEmirleri.Randevu_Tarihi) >='] = this.filterParams.randevu_starihi
      }
      if (this.filterParams.randevu_etarihi) {
        where['date(tbl_IsEmirleri.Randevu_Tarihi) <='] = this.filterParams.randevu_etarihi
      }
      if (this.filterParams.DB_Yazdirma_sTarihi) {
        where['date(tbl_IsEmirleri.DB_Yazdirma_Tarihi) >='] = this.filterParams.DB_Yazdirma_sTarihi
      }
      if (this.filterParams.DB_Yazdirma_eTarihi) {
        where['date(tbl_IsEmirleri.DB_Yazdirma_Tarihi) <='] = this.filterParams.DB_Yazdirma_eTarihi
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('serviceRPA/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceRPA/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
